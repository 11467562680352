import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarHome from '../components/navbar-home'
import Card from '../components/card'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Kaleau</title>
        <meta property="og:title" content="Kaleau" />
      </Helmet>
      <NavbarHome
        text1="About"
        imageSrc1="/asset%2011%20jpg%20file%20copy%20trans1-200h.png"
        rootClassName="navbar-home-root-class-name1"
      ></NavbarHome>
      <div className="home-hero hero-container section-container">
        <div className="home-max-width max-width">
          <div className="home-content">
            <span className="home-subtitle">The FREE Networking App</span>
            <h1 className="home-title">Keep track of your evolving network</h1>
            <span className="home-description">
              Stop using excel spreadsheets or expensive CRM&apos;s to handle
              your evolving network as it expands. Kaleau helps you keep your
              network organized and allows you to focus on what matters - life. 
            </span>
            <div className="home-container1">
              <a
                href="https://apps.apple.com/us/app/kaleau/id6504465205"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link button"
              >
                Install Now!
              </a>
            </div>
          </div>
          <div className="home-image">
            <div className="home-hero-image"></div>
          </div>
        </div>
      </div>
      <div className="home-section section-container">
        <div className="home-container2">
          <div className="home-content1">
            <span className="home-text beforeHeading">how it works</span>
            <h1 className="home-text01">
              <span>
                Set up your network
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
            </h1>
            <span className="home-text04">
              Create your own Offline network cards to keep track of
              conversations, remind your when to reach out again, or even keep
              personal notes on what to chat about next. You can even link
              existing users to Online network cards for an easier process.
            </span>
          </div>
        </div>
      </div>
      <div className="home-section1 section-container">
        <div className="home-max-width1 max-width">
          <div className="home-content2">
            <span className="home-text05 beforeHeading">Keep In touch</span>
            <h1 className="home-text06">
              <span className="home-text07">
                Add Friends
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text09">from your contacts list</span>
            </h1>
            <span className="home-text10">
              Decide who can reach out to you and when. Privacy is a huge
              concern. That&apos;s why we&apos;ve made it easy to silo out the
              people you don&apos;t want communicating you. 
            </span>
            <div className="home-container3">
              <button className="home-button button-secondary button bg-transparent">
                See how
              </button>
            </div>
          </div>
          <img
            alt="image"
            src="/srthsrthsrts-600w.png"
            className="home-image1"
          />
        </div>
      </div>
      <div className="home-section2 section-container">
        <div className="home-max-width2 max-width">
          <div className="home-image2">
            <a
              href="https://apps.apple.com/us/app/kaleau/id6504465205"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className="home-hero-image1">
                <div className="home-container4">
                  <h1 className="home-text11">
                    <span className="home-text12">
                      Tap to be
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br className="home-text13"></br>
                    <span className="home-text14">redirected</span>
                    <br></br>
                  </h1>
                </div>
              </div>
            </a>
          </div>
          <div className="home-content3">
            <span className="home-text16 beforeHeading">get started</span>
            <h1 className="home-text17">
              <span>Soon You Can Create an Account</span>
              <br></br>
            </h1>
            <div className="home-step">
              <div className="home-number">
                <span className="home-text20">1</span>
              </div>
              <div className="home-container5">
                <span className="home-title1">Download the Kaleau App</span>
                <span className="home-text21">
                  Click the photo the  left to redirect to the App Store. The
                  App is currently limitted to the United States.
                </span>
              </div>
            </div>
            <div className="home-step1">
              <div className="home-number1">
                <span className="home-text22">2</span>
              </div>
              <div className="home-container6">
                <span className="home-title2">Create your free account</span>
                <span className="home-text23">
                  Register using the App to login and start keeping track of
                  your network!
                </span>
              </div>
            </div>
            <div className="home-step2">
              <div className="home-number2">
                <span className="home-text24">3</span>
              </div>
              <div className="home-container7">
                <span className="home-title3">Start adding your Network</span>
                <span className="home-text25">
                  You can nurture your network by creating Offline custom
                  network cards or linking Online connections to keep track.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-container">
        <div className="home-max-width3 max-width">
          <span className="home-text26 beforeHeading">get started</span>
          <h1 className="home-text27">
            Kaleau handles a variety of use cases.
          </h1>
          <div className="home-cards-container">
            <Card rootClassName="card-root-class-name"></Card>
            <Card
              text="Personal"
              text1="Stay in touch with distant friends and family."
              rootClassName="card-root-class-name2"
            ></Card>
            <Card
              text="Community"
              text1="Staying organized with the community around you matters."
              rootClassName="card-root-class-name1"
            ></Card>
          </div>
        </div>
      </div>
      <div className="home-section4 section-container">
        <div className="home-max-width4 max-width">
          <div className="home-faq">
            <div className="home-questions">
              <span className="home-text28 beforeHeading">faq</span>
              <h1 className="home-text29">
                <span className="home-text30">
                  Frequently Asked
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="home-text31"></br>
                <span className="home-text32">Questions</span>
              </h1>
              <div data-role="Accordion" className="home-question question">
                <div data-type="accordion-header" className="home-trigger">
                  <span className="home-text33">
                    Is this a Free or Paid service?
                  </span>
                </div>
                <div className="home-container8">
                  <span className="home-text34">
                    As of now the app is completely free. There may be a point
                    in time where we need to monitize
                  </span>
                </div>
              </div>
              <div data-role="Accordion" className="question">
                <div data-type="accordion-header" className="home-trigger1">
                  <span className="home-text35">
                    Do you operate in United States?
                  </span>
                </div>
                <div data-type="accordion-content">
                  <span className="home-text36">
                    We currently only operate in the Unites States with the App
                    available in the US App Store only.
                  </span>
                </div>
              </div>
              <div data-role="Accordion" className="home-question2 question">
                <div data-type="accordion-header" className="home-trigger2">
                  <span className="home-text37">
                    Is this a globally available bank?
                  </span>
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                  </svg>
                </div>
                <div data-type="accordion-content">
                  <span className="home-text38">
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit.
                  </span>
                </div>
              </div>
              <div data-role="Accordion" className="home-question3 question">
                <div data-type="accordion-header" className="home-trigger3">
                  <span className="home-text39">
                    Do you have an iOS or Android app?
                  </span>
                </div>
                <div data-type="accordion-content">
                  <span className="home-text40">
                    Only iPhone apps are available. An Android App is in
                    development!
                  </span>
                </div>
              </div>
            </div>
            <img
              alt="image"
              src="/smartmockups_lxy24p90-1200w.jpg"
              className="home-image3"
            />
          </div>
          <div className="home-banner"></div>
        </div>
      </div>
    </div>
  )
}

export default Home
