import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import NavbarAbout from '../components/navbar-about'
import Card from '../components/card'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Kaleau</title>
        <meta property="og:title" content="About - Kaleau" />
      </Helmet>
      <NavbarAbout rootClassName="navbar-about-root-class-name"></NavbarAbout>
      <div className="about-container01">
        <div className="about-navbar navbar-container">
          <div className="max-width">
            <div className="about-logo">
              <Link to="/" className="about-navlink">
                <img
                  alt="image"
                  src="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                  className="about-image"
                />
              </Link>
              <Link to="/" className="about-navlink1">
                COFFEECHAT
              </Link>
            </div>
            <div className="about-links">
              <Link to="/about" className="about-text navbarLink">
                About
              </Link>
              <a
                href="https://apps.apple.com/us/app/acoffeechat/id1589893664"
                target="_blank"
                rel="noreferrer noopener"
                className="about-link button button-primary"
              >
                Install
              </a>
            </div>
            <div className="about-burger-menu navbar-burger-menu">
              <svg viewBox="0 0 1024 1024" className="about-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="about-hero hero-container section-container">
          <div className="about-max-width1 max-width">
            <div className="about-content">
              <span className="about-subtitle">The FREE Networking App</span>
              <h1 className="about-title">
                Keep track of your evolving network
              </h1>
              <span className="about-description">
                Stop using excel spreadsheets or expensive CRM&apos;s to handle
                your evolving network as it expands. CoffeeChat helps you keep
                your network organized and allows you to focus on what matters -
                life. 
              </span>
              <div className="about-container02">
                <a
                  href="https://apps.apple.com/us/app/acoffeechat/id1589893664"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="about-link1 button"
                >
                  Install Now!
                </a>
              </div>
            </div>
            <div className="about-image1">
              <div className="about-hero-image"></div>
            </div>
          </div>
        </div>
        <div className="about-section section-container">
          <div className="about-container03">
            <h1 className="about-text01">
              <span>About</span>
              <br></br>
            </h1>
            <div className="about-container04">
              <span className="about-text04">
                <span>
                  This project is designed to help college students manage their
                  networking.
                </span>
                <br></br>
                <br></br>
                <span>
                  I wanted to keep track of who I talked to through excel, but
                  it was an awful process. At first I tried setting up VBA to
                  send me emails when the date was triggered, but that required
                  me to open and close the sheet. I then tried using Python to
                  extract information from the sheet (while it was closed) but
                  that quickly became clunky.
                </span>
                <br></br>
                <br></br>
                <span>
                  I also didn&apos;t want to pay out of pocket for a SaaS that
                  does this (Salesforce, SmartSheet etc). So I built it!
                </span>
                <br></br>
                <br></br>
                <span>
                  Not all the features I want are available but this is the
                  initial beta.
                </span>
                <br></br>
                <br></br>
                <span>
                  It is currently designated to Georgia State University
                  students, but I&apos;ll soon be making it available for .edu
                  addresses globally.
                </span>
                <br></br>
                <br></br>
                <span>Let me know if you have any issues.</span>
              </span>
            </div>
          </div>
        </div>
        <div className="about-section1 section-container">
          <div className="about-max-width2 max-width">
            <div className="about-content1">
              <span className="about-text21 beforeHeading">Keep In touch</span>
              <h1 className="about-text22">
                <span className="about-text23">
                  Add Friends
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span> from your contacts list</span>
              </h1>
              <span className="about-text25">
                Decide who can reach out to you and when. Privacy is a huge
                concern. That&apos;s why we&apos;ve made it easy to silo out the
                people you don&apos;t want communicating you. 
              </span>
              <div className="about-container05">
                <button className="button-secondary button bg-transparent">
                  See how
                </button>
              </div>
            </div>
            <img
              alt="image"
              src="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
              className="about-image2"
            />
          </div>
        </div>
        <div className="about-section2 section-container">
          <div className="about-max-width3 max-width">
            <div className="about-image3">
              <a
                href="https://apps.apple.com/us/app/acoffeechat/id1589893664"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="about-hero-image1">
                  <div className="about-container06">
                    <h1 className="about-text26">
                      <span className="about-text27">
                        Tap to be
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <br className="about-text28"></br>
                      <span className="about-text29">redirected</span>
                      <br></br>
                    </h1>
                  </div>
                </div>
              </a>
            </div>
            <div className="about-content2">
              <span className="about-text31 beforeHeading">get started</span>
              <h1 className="about-text32">
                <span>Soon You Can Create an Account</span>
                <br></br>
              </h1>
              <div className="about-step">
                <div className="about-number">
                  <span className="about-text35">1</span>
                </div>
                <div className="about-container07">
                  <span className="about-title1">Download aCoffeeChat App</span>
                  <span className="about-text36">
                    Click the photo the  left to redirect to the App Store. The
                    App is currently limitted to the United States.
                  </span>
                </div>
              </div>
              <div className="about-step1">
                <div className="about-number1">
                  <span className="about-text37">2</span>
                </div>
                <div className="about-container08">
                  <span className="about-title2">Create your free account</span>
                  <span className="about-text38">
                    Register using the App to login and start keeping track of
                    your network!
                  </span>
                </div>
              </div>
              <div className="about-step2">
                <div className="about-number2">
                  <span className="about-text39">3</span>
                </div>
                <div className="about-container09">
                  <span className="about-title3">
                    Start adding your Network
                  </span>
                  <span className="about-text40">
                    You can nurture your network by creating Offline custom
                    network cards or linking Online connections to keep track.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-section3 section-container">
          <div className="about-max-width4 max-width">
            <span className="about-text41 beforeHeading">get started</span>
            <h1 className="about-text42">
              CoffeeChat handles a variety of use cases.
            </h1>
            <div className="about-cards-container">
              <Card rootClassName="card-root-class-name6"></Card>
              <Card
                text="Personal"
                text1="Stay in touch with distant friends and family."
                rootClassName="card-root-class-name7"
              ></Card>
              <Card
                text="Community"
                text1="Staying organized with the community around you matters."
                rootClassName="card-root-class-name8"
              ></Card>
            </div>
          </div>
        </div>
        <div className="about-section4 section-container">
          <div className="about-max-width5 max-width">
            <div className="about-faq">
              <div className="about-questions">
                <span className="about-text43 beforeHeading">faq</span>
                <h1 className="about-text44">
                  <span className="about-text45">
                    Frequently Asked
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <span className="about-text47">Questions</span>
                </h1>
                <div data-role="Accordion" className="about-question question">
                  <div data-type="accordion-header" className="about-trigger">
                    <span className="about-text48">
                      Is this a Free or Paid service?
                    </span>
                  </div>
                  <div className="about-container10">
                    <span className="about-text49">
                      As of now the app is completely free. There may be a point
                      in time where we need to monitize
                    </span>
                    <div
                      data-type="accordion-content"
                      className="about-answer question-content"
                    ></div>
                  </div>
                </div>
                <div data-role="Accordion" className="question">
                  <div data-type="accordion-header" className="about-trigger1">
                    <span className="about-text50">
                      Do you operate in United States?
                    </span>
                  </div>
                  <div
                    data-type="accordion-content"
                    className="question-content"
                  >
                    <span className="about-text51">
                      We currently only operate in the Unites States with the
                      App available in the US App Store only.
                    </span>
                  </div>
                </div>
                <div data-role="Accordion" className="about-question2 question">
                  <div data-type="accordion-header" className="about-trigger2">
                    <span className="about-text52">
                      Is this a globally available bank?
                    </span>
                    <svg viewBox="0 0 1024 1024" className="about-icon2">
                      <path d="M810 554h-256v256h-84v-256h-256v-84h256v-256h84v256h256v84z"></path>
                    </svg>
                  </div>
                  <div
                    data-type="accordion-content"
                    className="question-content"
                  >
                    <span className="about-text53">
                      Amet minim mollit non deserunt ullamco est sit aliqua
                      dolor do amet sint. Velit officia consequat duis enim
                      velit mollit.
                    </span>
                  </div>
                </div>
                <div data-role="Accordion" className="about-question3 question">
                  <div data-type="accordion-header" className="about-trigger3">
                    <span className="about-text54">
                      Do you have an iOS or Android app?
                    </span>
                  </div>
                  <div
                    data-type="accordion-content"
                    className="question-content"
                  >
                    <span className="about-text55">
                      Only iPhone apps are available. An Android App is in
                      development!
                    </span>
                  </div>
                </div>
              </div>
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"
                className="about-image4"
              />
            </div>
            <div className="about-banner"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
